import { styled } from 'styled-components';
import HNButton from './HNButton';
import { useRouter } from 'next/router';
import { Fonts } from '@/styles/fonts';
import { signOut, useSession } from 'next-auth/react';
import Image from 'next/image';
import ProfileDropdown from '@/components/ProfileDropdown';
import config from '@/lib/config';

const NavigationBar = () => {
    const router = useRouter();
    const { status } = useSession();

    const onContactClick = () => {
        router.push('/contact');
    };

    const onAboutClick = () => {
        router.push('/about');
    };

    const onProjectsClick = () => {
        router.push('/projects');
    };

    const onCatalogClick = () => {
        router.push('/catalog');
    };

    const onHomeClick = () => {
        router.push('/');
    };

    const onLoginClick = () => {
        router.push('/auth/signin');
    };

    const onSignOut = () => {
        signOut({ redirect: false }).then(() => {
            router.push('/');
        });
    };

    return (
        <Container>
            <InnerContainer>
                <LeftContainer>
                    {status !== 'authenticated' && (
                        <EmptyProfile src="/guest.svg" alt="Guest" width={32} height={32} onClick={onLoginClick} />
                    )}
                    {status === 'authenticated' && <ProfileDropdown onSignOut={onSignOut} />}
                    <ButtonsContainer>
                        <HNButton font={Fonts.OpenSans} onClick={onContactClick} style="sm-nav">
                            צור קשר
                        </HNButton>
                        <HNButton font={Fonts.OpenSans} onClick={onAboutClick} style="sm-nav">
                            אודות
                        </HNButton>
                        {status === 'authenticated' && (
                            <>
                                <HNButton font={Fonts.OpenSans} onClick={onProjectsClick} style="sm-nav">
                                    ההזמנות שלי
                                </HNButton>
                            </>
                        )}
                        <HNButton font={Fonts.OpenSans} onClick={onCatalogClick} style="sm-nav">
                            הזמנה חדשה
                        </HNButton>
                    </ButtonsContainer>
                </LeftContainer>
                <RightContainer>
                    <HNButton style="nav-logo" onClick={onHomeClick} font={Fonts.OpenSans}>
                        <InnerButtonContainer>
                            <StyledLogoText>הזמנינא</StyledLogoText>
                            <StyledLogo src="/smiling-letter.png" alt="hazmanina" width={50} height={50} />
                        </InnerButtonContainer>
                    </HNButton>
                </RightContainer>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    height: ${config.ui.navbarHeight};
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: black;
    align-items: center;

    @media screen and (min-width: 1800px) {
        border-radius: 6px 6px 0 0;
    }
`;

const InnerContainer = styled.div`
    height: 100%;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-inline-start: 30px;

    @media screen and (max-width: 900px) {
        margin-inline-start: 15px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 900px) {
        display: none;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-inline-start: 80px;
    gap: 60px;

    @media screen and (max-width: 900px) {
        display: none;
    }
`;

const Profile = styled(Image)`
    background-color: rgba(255, 255, 255, 0.87);
    border-radius: 50%;
    border: 2px solid #f3ffef;
    cursor: pointer;

    &:hover {
        background-color: white;
    }
`;

const InnerButtonContainer = styled.div`
    direction: ltr;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const StyledLogo = styled(Image)`
    margin-inline-end: 10px;
`;

const StyledLogoText = styled.div`
    line-height: 1;
    margin-inline-end: 10px;
`;

const EmptyProfile = styled(Profile)`
    border: 1px solid #f3ffef;
`;

export default NavigationBar;
